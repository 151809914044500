@import '../../styles/variables';

$badgeable-position-offset: 1px;
$badgeable-icon-size: 14px;

$badgeable-position: ($badgeable-icon-size/2);

.badgeable-container {
    position: relative;
    display: inline-block;
    line-height: 1;

    .badges {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        %badge {
            position: absolute;
            pointer-events: all;
        }

        .top-left-badge {
            @extend %badge;

            top: -$badgeable-position + $badgeable-position-offset;
            left: $badgeable-position + $badgeable-position-offset;
            transform: translateX(-100%);
        }

        .top-right-badge {
            @extend %badge;

            top: -$badgeable-position + $badgeable-position-offset;
            left: calc(100% - #{$badgeable-position + $badgeable-position-offset});
        }

        .bottom-left-badge {
            @extend %badge;

            bottom: -$badgeable-position + $badgeable-position-offset;
            left: $badgeable-position + $badgeable-position-offset;
            transform: translateX(-100%);
        }

        .bottom-right-badge {
            @extend %badge;

            bottom: -$badgeable-position + $badgeable-position-offset;
            left: calc(100% - #{$badgeable-position + $badgeable-position-offset});
        }
    }
}
