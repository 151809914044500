@import '../../styles/variables';

/**************************************
 * Pill Selector
 **************************************/

.pill-selector-hidden-input {
    position: absolute;
    visibility: hidden;
}

.pill-selector-wrapper {
    position: relative;
    margin: 0 0 20px;

    .pill-selector-input-wrapper {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: flex-start;
        width: 262px;
        margin-top: 5px;
        padding: 5px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: $white;
        border: 1px solid darken($white, 20%);
        border-radius: 2px;
        box-shadow: inset 0 1px 1px fade-out($black, .92);
        cursor: text;
        transition: border-color linear .15s, box-shadow linear .15s;
        -webkit-font-smoothing: antialiased;

        &:hover {
            border: 1px solid darken($primary-color, 10%);
        }

        &.is-disabled {
            color: $bdl-gray-30;
            background-color: $bdl-gray-02;
            box-shadow: none;

            &:hover {
                border-color: $bdl-gray-30;
            }
        }

        &.is-focused {
            border-color: $primary-color;
            outline: 0;
            box-shadow: none;
        }

        &.show-error {
            border-color: $bdl-watermelon-red;
        }

        &.pill-selector-suggestions-enabled {
            min-height: $bdl-line-height * 4;
        }

        &:not(.show-error) {
            .icon-alert {
                display: none;
            }
        }

        .icon-alert {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 16px;
            height: 16px;

            path {
                fill: $bdl-watermelon-red;
            }
        }

        .tooltip {
            top: calc(100% - 5px) !important;
            right: 10px !important;
            margin-top: 0 !important;
        }

        .pills-list {
            margin: 0;

            li {
                float: left;
            }
        }

        .pill-selector-input {
            flex: 1;
            box-sizing: content-box !important;
            min-width: 0;
            height: 18px;
            margin: 1px;
            padding: 3px;
            overflow: hidden;
            color: lighten($black, 31%);
            line-height: 18px;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            resize: none;

            &:focus,
            &:hover {
                outline: none;
            }
        }
    }
}
