/**************************************
 * Links
 **************************************/

/* Link styling for non-anchor tags such as buttons */

a,
.lnk {
    color: $bdl-box-blue;
    text-decoration: none;
}

.lnk {
    display: inline;
    height: auto;
    margin: 0;
    padding: 0;
    line-height: inherit;
    background: transparent none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    filter: none;
}

.lnk:hover,
.lnk:active {
    height: auto;
    line-height: inherit;
    background: transparent;
    box-shadow: none;
}

.lnk:active {
    color: $bdl-box-blue;
    text-decoration: underline;
}

a:focus,
.lnk:focus,
a.is-disabled:focus,
.lnk.is-disabled:focus {
    text-decoration: underline;
}

a.is-disabled,
a.is-disabled:hover,
a.is-disabled:active,
.lnk.is-disabled,
.lnk.is-disabled:hover,
.lnk.is-disabled:active {
    color: $bdl-gray-50;
    text-decoration: none;
    cursor: default;
}

.lnk-plain {
    color: inherit;
}

.lnk-plain:hover,
.lnk-plain:active {
    text-decoration: none;
}

.lnk-plain.is-disabled,
.lnk-plain.is-disabled:hover,
.lnk-plain.is-disabled:active {
    color: $bdl-gray-50;
}

a svg {
    pointer-events: none;
}

// Link styling from box-ui-elements: should probably get merged with the above?
// Also see nav-sidebar.scss before merging
.link {
    overflow: hidden;
    color: $bdl-gray-62;
    line-height: 1.5em;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    text-shadow: 1px 1px 1px fade-out($black, .996);
    transition: color .05s;
    font-smoothing: antialiased;

    &:hover {
        color: $bdl-box-blue;
    }
}

.link-group-title {
    color: $bdl-gray-40;
    font-size: 10px;
    line-height: 28px;
    letter-spacing: .1em;
    text-transform: uppercase;
}
