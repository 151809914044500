@import '../common/variables';
@import '~react-virtualized/styles.css';

.bcp-item-grid {
    flex: 1;

    // sass-lint:disable class-name-format
    .ReactVirtualized__Table__Grid {
        outline: none;
    }
}

.bcp-item-row {
    border-top: 1px solid $bdl-gray-10;
    border-bottom: 1px solid transparent;
    border-left: 2px solid transparent;
    outline: none;

    &.bcp-item-row-selected {
        background-color: $light-blue;
        border-top-color: $grey-blue;

        .be & .btn-plain,
        .be-item-label {
            color: $dark-cerulean;
            outline: none;
        }
    }

    &:first-child {
        /* stylelint-disable declaration-no-important */
        border-top-color: transparent !important;
        /* stylelint-enable declaration-no-important */
    }

    &:last-child {
        border-bottom: 1px solid $bdl-gray-10;

        &.bcp-item-row-selected {
            border-bottom-color: $grey-blue;
        }
    }

    &.bcp-item-row-unselectable .ReactVirtualized__Table__rowColumn {
        opacity: .3;
    }

    select {
        &.bcp-shared-access-select {
            background-image: none;
            border-color: transparent;

            &:active,
            &:focus {
                color: $blue;
                background-color: $white;
                background-image: linear-gradient(45deg, transparent 50%, $blue 50%), linear-gradient(135deg, $blue 50%, transparent 50%);
                border-color: $blue;
                outline: none;
            }
        }
    }

    .checkbox-container,
    .radio-container {
        margin: 0 0 0 1px;

        span,
        input {
            cursor: pointer;
        }
    }

    &:hover,
    &:active,
    &:focus {
        background-color: $lighter-light-blue;
        border-left-color: $dark-cerulean;

        select {
            &.bcp-shared-access-select {
                background-color: $white;
                background-image: linear-gradient(45deg, transparent 50%, $bdl-gray-62 50%), linear-gradient(135deg, $bdl-gray-62 50%, transparent 50%);
                border-color: $bdl-gray-20;
            }
        }

        .be & .btn-plain,
        .be-item-label {
            color: $dark-cerulean;
        }
    }

    &.bcp-item-row-selected + & {
        border-top-color: $grey-blue;
    }
}
