/**************************************
 * Pills
 **************************************/

$pillTextColorDark: lighten($black, 31%);

.pill,
.btn.pill {
    display: flex;
    flex: none;
    align-items: center;
    max-width: 100%;
    height: 24px;
    margin: 1px;
    padding: 2px 10px;
    overflow: hidden;
    color: $white;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: $primary-color;
    border-radius: 3px;

    .pill-text {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .close-btn {
        flex: none;
        margin: 0 0 0 4px;
        padding: 4px 0 4px 4px;
        color: $white;
        font-weight: bold;
        font-size: 8px;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    &.is-disabled {
        opacity: .5;

        .close-btn {
            cursor: default;
        }
    }

    &.is-invalid {
        background-color: $bdl-watermelon-red;
    }

    &.is-selected {
        box-shadow: inset 0 0 0 1px $white;
    }
}

.pill-warning {
    color: $bdl-yellorange;
    background-color: $bdl-yellorange-10;
    border: 1px solid $bdl-yellorange-50;

    a {
        color: $bdl-yellorange;
    }
}

.pill-error {
    color: $bdl-watermelon-red;
    background-color: $bdl-watermelon-red-10;
    border: 1px solid $bdl-watermelon-red-50;

    a {
        color: $bdl-watermelon-red;
    }
}

.pill.pill-cloud-button {
    display: inline-block;
    margin: 3px;
    color: $primary-color;
    background-color: $white;
    border: 1px solid $primary-color;

    &.is-selected {
        box-shadow: none;
    }

    &:not(.is-disabled) {
        &:focus {
            border-color: darken($primary-color, 8%);
            box-shadow: 0 1px 2px fade-out($black, .9);
        }
    }
}

.pill-cloud-container {
    padding: 8px;
    overflow-y: scroll;
    border: 1px solid $bdl-gray-10;
}
