@import '../common/variables';

.bcu-footer {
    display: flex;
    flex: 0 0 70px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: $almost-white;
    border-top: 1px solid $bdl-gray-10;

    .bcu-footer-message {
        padding: 0 10px;
        text-align: center;
    }

    .bcu-footer-right .btn {
        margin-left: 8px;
    }
}
