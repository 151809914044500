@import '../../styles/variables';

/**************************************
 * Tooltip
 **************************************/

$arrow-size: 6px;
$horizontal-offset: 10px;
$tooltip-offset: 4px; /* amount of space between target and tooltip arrow */

.tooltip {
    position: relative;
    max-width: 200px;
    padding: 8px $horizontal-offset;
    color: $white;
    font-size: 13px;
    line-height: 1.4;
    word-wrap: break-word;
    background-color: $bdl-gray-80;
    border-color: $bdl-gray-80;
    border-radius: 4px;

    &::before {
        position: absolute;
        width: 0;
        height: 0;
        border: $arrow-size solid transparent;
        content: '';
    }

    &.is-error::after {
        position: absolute;
        width: 0;
        height: 0;
        border: $arrow-size - 1 solid transparent;
        content: '';
    }

    &.is-error {
        color: $bdl-gray;
        background-color: $bdl-watermelon-red-10;
        border: 1px solid $bdl-watermelon-red-50;

        .tooltip-close-button .icon-close .fill-color {
            fill: $bdl-gray;
        }
    }

    &.is-callout {
        background-color: $bdl-purple-rain;
        border-color: $bdl-purple-rain;
    }

    &.is-metadata-tooltip {
        margin-left: -8px;
    }

    &.with-close-button {
        padding-right: 28px;
    }

    .tooltip-close-button {
        position: absolute;
        top: 9px;
        right: 3px;
        cursor: pointer;

        .icon-close {
            height: 16px;

            .fill-color {
                fill: $white;
            }
        }
    }

    .tooltip-target-attached-top.tooltip-target-attached-center > & {
        margin-bottom: $arrow-size + $tooltip-offset;

        &::before {
            top: 100%;
            left: 50%;
            margin-left: -$arrow-size;
            border-top-color: inherit;
        }

        &.is-error::after {
            top: 100%;
            left: 50%;
            margin-left: -$arrow-size + 1;
            border-top-color: $bdl-watermelon-red-10;
        }
    }

    .tooltip-target-attached-top.tooltip-target-attached-left > & {
        margin-bottom: $arrow-size + $tooltip-offset;

        &::before {
            top: 100%;
            left: $horizontal-offset;
            border-top-color: inherit;
        }

        &.is-error::after {
            top: 100%;
            left: $horizontal-offset + 1;
            border-top-color: $bdl-watermelon-red-10;
        }
    }

    .tooltip-target-attached-top.tooltip-target-attached-right > & {
        margin-bottom: $arrow-size + $tooltip-offset;

        &::before {
            top: 100%;
            right: $horizontal-offset;
            border-top-color: inherit;
        }

        &.is-error::after {
            top: 100%;
            right: $horizontal-offset + 1;
            border-top-color: $bdl-watermelon-red-10;
        }
    }

    .tooltip-target-attached-middle.tooltip-target-attached-right > & {
        margin-left: $arrow-size + $tooltip-offset;

        &::before {
            top: 50%;
            right: 100%;
            margin-top: -$arrow-size;
            border-right-color: inherit;
        }

        &.is-error::after {
            top: 50%;
            right: 100%;
            margin-top: -$arrow-size + 1;
            border-right-color: $bdl-watermelon-red-10;
        }
    }

    .tooltip-target-attached-middle.tooltip-target-attached-left > & {
        margin-right: $arrow-size + $tooltip-offset;

        &::before {
            top: 50%;
            left: 100%;
            margin-top: -$arrow-size;
            border-left-color: inherit;
        }

        &.is-error::after {
            top: 50%;
            left: 100%;
            margin-top: -$arrow-size + 1;
            border-left-color: $bdl-watermelon-red-10;
        }
    }

    .tooltip-target-attached-bottom.tooltip-target-attached-center > & {
        margin-top: $arrow-size + $tooltip-offset;

        &::before {
            bottom: 100%;
            left: 50%;
            margin-left: -$arrow-size;
            border-bottom-color: inherit;
        }

        &.is-error::after {
            bottom: 100%;
            left: 50%;
            margin-left: -$arrow-size + 1;
            border-bottom-color: $bdl-watermelon-red-10;
        }
    }

    .tooltip-target-attached-bottom.tooltip-target-attached-left > & {
        margin-top: $arrow-size + $tooltip-offset;

        &::before {
            bottom: 100%;
            left: $horizontal-offset;
            border-bottom-color: inherit;
        }

        &.is-error::after {
            bottom: 100%;
            left: $horizontal-offset + 1;
            border-bottom-color: $bdl-watermelon-red-10;
        }
    }

    .tooltip-target-attached-bottom.tooltip-target-attached-right > & {
        margin-top: $arrow-size + $tooltip-offset;

        &::before {
            right: $horizontal-offset;
            bottom: 100%;
            border-bottom-color: inherit;
        }

        &.is-error::after {
            right: $horizontal-offset + 1;
            bottom: 100%;
            border-bottom-color: $bdl-watermelon-red-10;
        }
    }
}

// tooltip is a tethered element and as such attached to body
// however the body may not have box specific classes and hence the
// tooltip needs to manually inherit few of the body styles to look correct.
.tooltip-element {
    @include box-sizing;
    @include common-typography;

    z-index: $tooltip-z-index;
}
