@import '../common/variables';

.bcu-progress-container {
    z-index: 201;
    width: 100%;
    height: 2px;
    margin-right: 40px;
    background: $bdl-gray-10;
    transition: .4s opacity;

    .bcu-progress {
        top: 0;
        left: 0;
        max-width: 100%;
        height: 2px;
        background: $blue;
        box-shadow: 0 1px 5px 0 $light-blue;
        transition: .1s width;
    }
}
