@import '../../styles/variables';

.aria-menu {
    margin: 0;
    padding: 8px 0;
    white-space: nowrap;
    list-style: none;
    background: $white;
    border: 1px solid $bdl-gray-30;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 fade-out($black, .9);

    &:not(.should-outline-focus):focus {
        outline: none;
    }

    [role='separator'] {
        height: 0;
        margin: 8px 0;
        border: 0;
        border-top: 1px solid $bdl-gray-30;
    }

    a,
    a:hover,
    a:focus,
    .lnk,
    .lnk:hover,
    .lnk:focus {
        text-decoration: none;
    }
}

.menu-item {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 30px;
    color: $bdl-gray-80;
    cursor: pointer;

    &,
    &.btn-plain,
    &.btn-plain:hover,
    &.btn-plain:active {
        padding: 5px 35px 5px 15px;
    }

    &.menu-section-header {
        color: $black;
        font-weight: bold;
        font-size: 10px;
    }

    &[aria-disabled] {
        cursor: default;
        opacity: .4;
    }

    &.is-select-item {
        position: relative;
        padding-left: 30px;

        /* checkmark */
        &.is-selected::before {
            position: absolute;
            top: 8px;
            left: 14px;
            width: 6px;
            height: 12px;
            margin: auto;
            border-color: $bdl-gray-80;
            border-style: none solid solid none;
            border-width: 2px;
            transform: rotate(45deg);
            content: '';
        }
    }

    &:not([aria-disabled]) {
        &:focus,
        &:hover {
            color: $bdl-gray-80;
            background-color: $bdl-gray-10;
        }

        &:focus {
            outline: 1px dashed $bdl-gray;
        }
    }

    svg {
        margin-right: 10px;
    }
}
