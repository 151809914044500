// Box Design Language Colors
// confirm with design before extending

$white: #fff !default;
$black: #000 !default;

// Box/Primary Blues
$bdl-box-blue: #0061d5 !default;
$bdl-box-blue-80: #3381dd !default;
$bdl-box-blue-50: #619de5 !default;
$bdl-box-blue-40: #99c0ee !default;
$bdl-box-blue-30: #b2cff2 !default;
$bdl-box-blue-20: #ccdff7 !default;
$bdl-box-blue-10: #e5effa !default;
$bdl-box-blue-05: #f2f7fd !default;
$bdl-box-blue-02: #fafcfe !default;

// Box/Primary Grays
$bdl-gray: #222 !default;
$bdl-gray-80: #4e4e4e !default;
$bdl-gray-62: #767676 !default;
$bdl-gray-50: #909090 !default;
$bdl-gray-40: #a7a7a7 !default;
$bdl-gray-30: #bcbcbc !default;
$bdl-gray-20: #d3d3d3 !default;
$bdl-gray-10: #e8e8e8 !default;
$bdl-gray-05: #f4f4f4 !default;
$bdl-gray-02: #fbfbfb !default;

// Dark Blues
$bdl-dark-blue: #003c84 !default;
$bdl-dark-blue-50: #7f9dc1 !default;
$bdl-dark-blue-10: #e5ebf2 !default;
$bdl-dark-blue-05: #f2f5f9 !default;
$bdl-dark-blue-02: #fafbfd !default;

// Light Blues
$bdl-light-blue: #2486fc !default;
$bdl-light-blue-50: #91c2fd !default;
$bdl-light-blue-10: #e9f2fe !default;
$bdl-light-blue-05: #f4f9ff !default;
$bdl-light-blue-02: #fbfdff !default;

// Yelloranges
$bdl-yellorange: #f5b31b !default;
$bdl-yellorange-50: #fad98d !default;
$bdl-yellorange-10: #fef7e8 !default;
$bdl-yellorange-05: #fefbf3 !default;
$bdl-yellorange-02: #fffefb !default;

// Yellows
$bdl-yellow: #ffd700 !default;
$bdl-yellow-50: #ffeb7f !default;
$bdl-yellow-10: #fffbe5 !default;
$bdl-yellow-05: #fffdf2 !default;
$bdl-yellow-02: #fffefa !default;

// Green Lights
$bdl-green-light: #26c281 !default;
$bdl-green-light-50: #92e0c0 !default;
$bdl-green-light-10: #e9f8f2 !default;
$bdl-green-light-05: #f4fcf8 !default;
$bdl-green-light-02: #fbfefd !default;

// Grimaces
$bdl-grimace: #4826c2 !default;
$bdl-grimace-50: #a392e0 !default;
$bdl-grimace-10: #ece9f8 !default;
$bdl-grimace-05: #f6f4fc !default;
$bdl-grimace-02: #fbfbfe !default;

// Purple Rains
$bdl-purple-rain: #9f3fed !default;
$bdl-purple-rain-50: #cf9ff6 !default;
$bdl-purple-rain-10: #f5ebfd !default;
$bdl-purple-rain-05: #faf5fe !default;
$bdl-purple-rain-02: #fdfbff !default;

// Watermelon Reds
$bdl-watermelon-red: #ed3757 !default;
$bdl-watermelon-red-50: #f69bab !default;
$bdl-watermelon-red-10: #fdebee !default;
$bdl-watermelon-red-05: #fef5f6 !default;
$bdl-watermelon-red-02: #fffbfc !default;
