@import '../../styles/variables';

/**************************************
 * Button Group
 **************************************/
.btn-group {
    position: relative;

    .toggle-overlay {
        display: inline;

        > .btn {
            margin-left: 0;
            padding-right: 11px;
            padding-left: 11px;
            border-radius: 0 4px 4px 0;

            &.btn-primary {
                border-left-color: darken($primary-color, 10%);
            }
        }

        .icon-caret-down {
            &::after {
                content: '';
            }

            path {
                fill: $white;
            }
        }

        .overlay-wrapper {
            position: static;

            .overlay {
                left: 0;
            }
        }
    }

    > .btn {
        margin: 5px 0 5px -1px;
        border-radius: 0;

        &.btn-primary {
            margin: 5px 0;
            border-right-color: darken($primary-color, 10%);

            &.is-selected {
                color: $white;
                background-color: darken($primary-color, 8%);
                border-color: darken($primary-color, 15%);
                box-shadow: none;
            }

            &:last-of-type {
                border-right-color: $primary-color;
            }
        }
    }

    > .btn:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    > .btn:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    > .btn.is-selected {
        z-index: 2; /* place on top of siblings */
        color: $bdl-gray-80;
        background-color: $bdl-gray-10;
        border-color: $bdl-gray-62;
        box-shadow: none;
    }

    > .btn:focus {
        z-index: 3; /* place on top of all other buttons for accessibility */
    }

    &.is-disabled {
        > .btn {
            color: $bdl-gray-62;
            background-color: $bdl-gray-02;
            border: 1px solid $bdl-gray-30;
            box-shadow: none;
            cursor: default;
            opacity: .4;
        }

        > .btn-primary {
            color: $white;
            background-color: $primary-color;
            border-color: $primary-color;
            border-right-color: darken($primary-color, 10%);

            &:last-of-type {
                border-right-color: $primary-color;
            }
        }
    }
}
