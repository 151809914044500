@import '../../styles/variables';

/**************************************
 * Checkbox
 **************************************/

.checkbox-label {
    position: relative;
    display: inline-flex;
    user-select: none;

    > input[type='checkbox'] {
        flex: none;
        width: 14px;
        height: 14px;
        margin: 3px 0 0;
        opacity: 0;

        + span {
            &::before {
                position: absolute;
                top: 3px;
                left: 0;
                width: 14px;
                height: 14px;
                background: $white;
                border: 1px solid $bdl-gray-50;
                border-radius: 2px;
                content: '';
            }

            &::after {
                position: absolute;
                top: 6px;
                left: 5px;
                width: 4px;
                height: 7px;
                border-right: 2px solid $primary-color;
                border-bottom: 2px solid $primary-color;
                transform: rotate(45deg) scale(0) translate3d(0, 0, 0);
                backface-visibility: hidden;
                transition: transform .1s ease;
                content: '';
            }
        }

        &:checked + span::after {
            transform: rotate(45deg) scale(1) translate3d(0, 0, 0);
        }

        &:focus + span::before {
            border-color: $primary-color;
        }

        &:disabled {
            + span::before {
                background-color: $bdl-gray-02;
                border: 1px solid $bdl-gray-20;
            }

            + span::after {
                border-color: $bdl-gray-30;
            }
        }

        + span + span {
            margin-left: 10px;
        }
    }
}

.checkbox-tooltip-wrapper {
    display: flex;
    align-items: center;

    > .info-tooltip {
        position: relative;
        flex: none;
        width: 16px;
        height: 16px;
        margin-left: 5px;

        > svg path {
            fill: $primary-color;
        }
    }
}

.checkbox-container {
    margin: 0 0 20px;

    &.is-disabled .checkbox-label {
        color: $bdl-gray-50;
    }

    > .label {
        margin: 8px 0;
    }
}

.checkbox-subsection,
.checkbox-description {
    margin-top: 2px;
    margin-left: 24px;
}

.checkbox-description {
    color: $bdl-gray-50;
}
