@import '../../styles/variables';

.submenu-target {
    position: relative;

    &::after {
        position: absolute;
        top: 12px;
        right: 10px;
        border: 4px solid transparent;
        border-right-style: none;
        border-left-color: $bdl-gray-30;
        content: '';
    }

    .submenu {
        position: absolute;
        top: 0;
        right: auto;
        left: 100%;

        &.is-left-aligned {
            right: 100%;
            left: auto;
        }

        &.is-hidden {
            right: auto;
            left: -9999px;
        }

        &.is-bottom-aligned {
            top: auto;
            bottom: 0;
        }
    }
}
