@import '../variables';

.be-pagination {
    display: flex;

    .be-pagination-count {
        display: flex;
        align-items: center;

        .be-is-small & {
            display: none;
        }
    }

    .be-pagination-toggle {
        margin-right: 5px;
        margin-left: 5px;
    }
}
